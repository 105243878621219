#myProgress {
    position: relative;
    width: 100%;
    height: 30px;
    background-color: #ddd;
  }
  
  #myBar {
    /* position: absolute; */
    width: 10%;
    height: 100%;
    background-color: #3da8a4;
  }
  
  #label {
    text-align: center;
    line-height: 30px;
    color: white;
  }
