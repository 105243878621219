/* Pasha styles – Brand-overrides, can split these out */
:root {
  --accent-color: #ed5f74;
  --success-color: #5fed82;
  --headline-color: var(--accent-color);
  --logo-image: url("https://storage.googleapis.com/stripe-sample-images/logo-pasha.svg");
}

.pasha-image-stack {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: auto auto;
}

.pasha-image-stack img {
  border-radius: var(--radius);
  background-color: var(--gray-border);
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  transition: all 0.8s ease;
  opacity: 0;
}

.pasha-image-stack img:nth-child(1) {
  transform: translate(12px, -12px);
  opacity: 1;
}

.pasha-image-stack img:nth-child(2) {
  transform: translate(-24px, 16px);
  opacity: 1;
}

.pasha-image-stack img:nth-child(3) {
  transform: translate(68px, -100px);
  opacity: 1;
}

.sample-info {
  padding: 20px 30px;
  border: 1px solid var(--gray-border);
  border-radius: var(--radius);

  position: fixed;
  top: 10px;
  left: 50%;
  margin-left: -250px;
  max-width: 500px;
}

@media (max-width: 720px) {
  .sample-info {
    top: 0;
  }
}

.button-logout {
  /* padding: 7px 12px 5px 12px; */
  /* margin-top: 15px; */
  margin-left: 5px;
  color: #fff;
  background-color: transparent;
  outline: none !important;
  border: none;
  cursor: pointer;
}

.login-failed {
  background-color: red;
  color: #fff;
}

.btn-delete {
  background-color: red;
  color: #fff !important;
  margin-right: 20px;
}

.btn-delete:hover {
  background-color: white;
  color: red !important;
}

.btn-signup {
  color: #fff !important;
}

.color-green {
  color: #3da8a4 !important;
}

.l-login {
  padding: unset !important;
}

table.dataTable thead .sorting {
  background-image: unset;
}

table.dataTable thead .sorting_asc {
  background-image: unset;
}

table.dataTable thead .sorting_asc:after {
  content: unset;
}

table.dataTable thead .sorting_desc:after {
  content: unset;
}

table.dataTable thead .sorting_desc {
  background-image: unset;
}

table.dataTable thead .sorting:after {
  content: unset;
}

table tr td .dropdown-content a.rowOption {
  filter: alpha(opacity=100);
  opacity: 1;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  cursor: pointer;
}
/* table tr td a.rowOption {
  filter: alpha(opacity=100);
  opacity: 0;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
} */

/* table tr:hover td a.rowOption {
  filter: alpha(opacity=100);
  opacity: 1;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  cursor: pointer;
} */
table tr td .dropdown-content a.rowOption {
  filter: alpha(opacity=100);
  opacity: 1;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  cursor: pointer;
}
/* table tr td .dropDown:hover ~ a.rowOption{
  
  filter: alpha(opacity=100);
  opacity: 1;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  cursor: pointer;
} */

.rowOption img {
  transition: transform 0.4s;
  /* transform: scale(0.5); */
  width: 24px;
  height: 24px;
  padding: 4px;
}
.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 350px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.show.dropdown-content {
  display: block;
  position: absolute;
}

.editClass img:hover {
  transform: scale(1.3);
  border-radius: 50%;
  background-color: #479ef5;
}

.deleteClass img:hover {
  transform: scale(1.3);
  border-radius: 50%;
  background-color: #f44336;
}

.lockClass img:hover {
  transform: scale(1.3);
  border-radius: 50%;
  background-color: #3da8a4;
}

.pdfClass img:hover {
  transform: scale(1.3);
  border-radius: 50%;
  background-color: #3da8a4;
}

.delete-buttons {
  text-align: center;
}

.IdleSession-buttons {
  text-align: center;
}

.sidebar {
  z-index: unset;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background-color: #3da8a4 !important;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
  display: inline-block;
  cursor: pointer;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: red;
}

.inputfile:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

/* For Styling Dropdown */
.dropbtn {
  color: white;
  height: 20px;
  width: 20px;
  margin-top: -30px;
  border: none;
  cursor: pointer;
  align-self: center;
  align-content: center;
  margin-left: 50px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  width: 200px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 140px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  align-self: flex-start;
  z-index: 1;
  margin-left: -60px;
  margin-top: -10px;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

/* For Styling Dropdown */

section.content {
  margin: 62px 0px 15px 265px;
}

.label {
  margin: 0 0 0 10px;
}

.form-group {
  margin: unset;
}

.form-control {
  height: unset;
  line-height: unset;
  padding: 0px;
}

.dropzone .dz-message .drag-icon-cph .material-icons {
  font-size: 40px;
}

.patient-drop-file {
  font-size: 20px;
}

.patient-add .body {
  line-height: unset;
}

/* FOR REACT-SELECT */
/* .css-1okebmr-indicatorSeparator{
  background-color: transparent !important;
}
.css-1uccc91-singleValue {
  color: white !important;
} 
.css-6q0nyr-Svg {
  fill: white !important;
}
.css-1wa3eu0-placeholder {
  color: white !important;
} */
/* FOR REACT-SELECT */

.react-date-picker__wrapper {
  border: unset !important;
}

.color-red {
  color: red;
}

.css-1p2i15d-control {
  min-height: 35px;
}

body.busy-cursor {
  cursor: progress;
}

.status_font {
  font-size: 8px;
}

.positive_negative_image {
  height: 46px;
  width: 50px;
  margin: 0 0 0 22px;
}

/* ============= */
/* Graph related */
/* ============= */
.graph-container {
  height: 60vh;
  padding-top: 5vh;
}

.graph-outer-box {
  padding: 20px 15px;
}

.graph-title,
.graph-options-container {
  font-size: 0.85vw;
  margin-bottom: 1vh;
}

.graph-options-container {
  margin-bottom: 3vh;
}

.graph-button {
  background: #fff;
  border: none;
  margin: 0 1.75px;
  padding: 2.5px 10px;
  cursor: pointer;
  border-color: transparent;
  border-radius: 10px;
  transition: all 0.25s ease-in-out;
}

.graph-button.selected,
.graph-button:hover {
  background-color: #232323;
  color: #fff;
}

.atat-value-display-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.atat-inner {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.atat-inner p {
  color: #232323;
  margin: 0;
}

.atat-inner p:first-child {
  font-size: 8.5vw;
  margin-top: -5vw;
}

.atat-inner p:last-child {
  font-size: 2vw;
  font-weight: 500;
  text-transform: lowercase;
  letter-spacing: 0.25vw;
  margin-top: -2vw;
  margin-left: 0.75vw;
}

/* ====== */
/* Report review buttons */
/* ====== */
.btn-report-review {
  width: fit-content;
  height: fit-content;
  margin-left: 10px;
}

/* ===== */
/* Forgot password section */
/* ===== */
.forgot-password-section {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.reset-password-form-box {
  width: 50%;
  height: fit-content;
  color: #232323;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.reset-password-form-box input {
  width: 100%;
  font-size: 3vw;
  font-weight: 300;
  letter-spacing: 0.25vw;
  text-align: center;
  padding: 0.95vw 0.35vw;
  border: none;
  border-bottom: 0.1vw solid rgba(35, 35, 35, 0.35);
  transition: all 0.25s ease-in-out;
}

.reset-password-form-box input:focus {
  border-bottom: 0.1vw solid rgba(35, 35, 35, 0.65);
  outline: none;
}

.reset-password-form-box .err-email-input {
  border-bottom: 0.1vw solid #ed5f74 !important;
  position: relative;
  animation: moveSlightly 0.25s 2 ease;
}

@keyframes moveSlightly {
  0%,
  50%,
  100% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(0.75deg);
  }

  75% {
    transform: rotate(-0.75deg);
  }
}

.reset-password-form-box button {
  width: fit-content;
  color: #fff;
  font-size: 1.25vw;
  font-weight: 300;
  letter-spacing: 0.1vw;
  text-transform: lowercase;
  padding: 0.65vw 2.25vw;
  margin-top: 2.55vw;
  border: 0.1vw solid;
  border-radius: 2.5vw;
  background-color: rgb(61, 168, 164);
  outline: none;
  transition: all 0.25s ease;
}

.reset-password-form-box button:focus,
.reset-password-form-box button:hover {
  cursor: pointer;
  outline: none;
  background-color: rgb(61, 168, 164);
  color: #fff;
  border-color: rgb(61, 168, 164);
}

.reset-password-request-message-box {
  width: 50%;
  height: 0px;
  color: #232323;
  font-size: 0.95vw;
  font-weight: 400;
  text-align: center;
  margin-top: 1vw;
  overflow: hidden;
}

.height-fitcontent {
  height: 0px;
  animation: appearSlowly 0.5s 1 ease-in forwards;
}

.height-0px {
  height: fit-content;
  animation: hideSlowly 0.5s 1 ease-out forwards;
}

@keyframes appearSlowly {
  0% {
    height: 0px;
    opacity: 0.25;
    margin-top: 1vw;
  }

  100% {
    height: fit-content;
    opacity: 1;
    margin-top: 1.65vw;
  }
}

@keyframes hideSlowly {
  0% {
    height: fit-content;
    opacity: 1;
    margin-top: 1.65vw;
  }

  100% {
    height: 0px;
    opacity: 0.25;
    margin-top: 1vw;
  }
}

.forgot-password-section footer {
  position: absolute;
  bottom: 2.5vw;
  right: 5vw;
  opacity: 0.75;
}

/* ===== */
/* Forgot password reset page */
/* ===== */
.forgot-password-reset-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.request-processing-animation-box {
  width: fit-content;
  height: fit-content;
  padding: 2.5vw;
  border-radius: 0.5vw;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.circle-loader-animation {
  width: 3vw;
  height: 3vw;
  border: 0.25vw solid #232323;
  border-right-color: transparent;
  border-radius: 50%;
  animation: keepSpinning 1.5s linear infinite;
}

@keyframes keepSpinning {
  0%,
  100% {
    transform: rotate(0deg);
  }

  99% {
    transform: rotate(360deg);
  }
}

.request-processing-animation-box ~ p {
  height: 0px;
  color: #232323;
  font-size: 1.15vw;
  font-weight: 300;
  letter-spacing: 0.5vw;
  overflow: hidden;
  margin-top: 0vw;
  animation: emergeSlowly 1.75s 0.5s ease-in-out 1 forwards;
}

@keyframes emergeSlowly {
  0% {
    height: 0px;
    margin-top: 0vw;
    opacity: 0;
  }

  100% {
    height: fit-content;
    margin-top: 3.5vw;
    opacity: 1;
  }
}

.verification-feedback-message {
  width: 65%;
  font-size: 2.15vw;
  font-weight: 300;
  text-align: center;
  letter-spacing: 0.5vw;
  margin: 0;
  position: relative;
  animation: bounceUpDown 0.5s ease-in-out forwards;
}

@keyframes bounceUpDown {
  0%,
  100% {
    font-size: 2.15vw;
  }

  50% {
    font-size: 2.25vw;
  }
}

.margin-top-2vw {
  margin-top: 2vw;
}

.card-display {
  padding: 7.5vw 12vw;
  border-radius: 2.5vw;
  opacity: 0;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  position: relative;
  animation: popUpSlowly 1s ease-out 1 forwards;
}

@keyframes popUpSlowly {
  0% {
    margin-top: 2.25vw;
    opacity: 0.25;
  }

  100% {
    margin-top: 0vw;
    opacity: 1;
  }
}

.card-display input,
.card-display button {
  font-size: 1vw;
}

.request-processing-animation-box ~ .no-animation {
  animation: none;
  height: fit-content;
  margin-top: 3.5vw;
  opacity: 1;
}

.margin-top-30 {
  margin: 30px 0 0 0;
}

/* ==== */
/* Track Order Form - Positive Reports Page */
/* ==== */
.track-order-form-container,
.view-track-info-container,
.fax-out-form-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 85vh;
  background-color: rgba(255, 255, 255, 0.55);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.track-order-form-container .track-order-form-box,
.view-track-info-container .track-order-info-box,
.fax-out-form-container .fax-out-form-box {
  width: 50%;
  height: 75%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 7.5vw 8.5vw;
}

.track-order-form-box input[type="text"],
.view-track-info-container p,
.fax-out-form-container p,
.fax-out-form-box input[type="text"] {
  width: 100%;
  font-size: 1vw;
  font-weight: 300;
  letter-spacing: 0.15vw;
  padding: 0.75vw 0.35vw;
  border: none;
  border-bottom: 0.1vw solid rgba(61, 168, 164, 0.35);
  transition: all 0.25s ease-in-out;
}

.track-order-form-box input[type="text"]:focus,
.fax-out-form-box input[type="text"]:focus {
  border-bottom: 0.1vw solid rgba(61, 168, 164, 0.75);
  outline: none;
}

.track-order-label,
.fax-out-order-label {
  width: 100%;
  font-size: 0.95vw;
  letter-spacing: 0.1vw;
  color: rgba(61, 168, 164, 0.85);
}

.track-order-form-box #trackNumber ~ span {
  width: 100%;
  height: fit-content;
  display: block;
  margin-top: 5%;
}

.track-receipt-uploader {
  width: 100%;
  height: 25%;
  padding: 5% 10%;
}

.track-order-form-box button[type="submit"],
.fax-out-form-box button[type="submit"] {
  width: 100%;
  color: #fff;
  font-size: 0.95vw;
  font-weight: 300;
  letter-spacing: 0.25vw;
  text-transform: lowercase;
  padding: 0.75vw 2.25vw;
  margin-top: 2.55vw;
  border: 0.1vw solid;
  border-radius: 2.5vw;
  background-color: rgb(61, 168, 164);
  outline: none;
  transition: all 0.25s ease;
}

.track-order-form-box button[type="submit"]:focus,
.track-order-form-box button[type="submit"]:hover,
.fax-out-form-box button[type="submit"]:focus,
.fax-out-form-box button[type="submit"]:hover {
  cursor: pointer;
  outline: none;
  background-color: rgb(61, 168, 164);
  color: #fff;
  border-color: rgb(61, 168, 164);
}

.track-order-form-close-button,
.track-order-info-close-button,
.fax-out-form-close-button {
  width: 3vw;
  height: 3vw;
  position: absolute;
  bottom: -2.65vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #232323;
  outline: none;
  background: #fff;
  padding: 0.85vw;
  border: none;
  border-radius: 50%;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  cursor: pointer;
}

.track-order-form-close-button .close-line,
.track-order-info-close-button .close-line,
.fax-out-form-close-button .close-line {
  width: 100%;
  height: 0.1vw;
  background-color: #232323;
  transition: all 0.25s ease-in-out;
}

.track-order-form-close-button:hover .close-line,
.track-order-info-close-button:hover .close-line,
.fax-out-form-close-button:hover .close-line {
  background-color: #f44336;
}

.circle-loader-positive-report-content {
  width: 5vw;
  height: 5vw;
  border: 0.35vw solid rgb(61, 168, 164);
  border-radius: 50%;
  border-left-color: transparent;
  animation: spin360deg 1.35s linear infinite;
}

@keyframes spin360deg {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359.99deg);
  }
}

.view-track-info-container .track-order-info-edit-button {
  width: fit-content;
  height: fit-content;
  position: absolute;
  bottom: 6vh;
  left: 30vw;
  font-size: 0.85vw;
  letter-spacing: 0.05vw;
  text-align: left;
  display: flex;
  align-items: left;
  justify-content: center;
  color: rgb(61, 168, 164);
  outline: none;
  background: #fff;
  padding: 0.5vw 0.75vw;
  border: none;
  border-radius: 1.75vw;
  cursor: pointer;
  transition: all 0.35s ease-in-out;
}

.track-order-info-edit-button span {
  width: 0;
  opacity: 0.35;
  overflow: hidden;
  transition: all 0.35s ease-in-out;
}

.track-order-info-edit-button:hover span {
  width: 1.5vw;
  opacity: 1;
}

.track-order-info-edit-button:hover {
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}

.ant-radio-group {
  width: 100%;
  margin-top: 0.65vw;
}

.ant-radio-wrapper {
  width: 47%;
}

.ant-radio-checked .ant-radio-inner {
  border-color: rgb(64, 168, 164);
}

.ant-radio-inner::after {
  background-color: rgb(64, 168, 164);
}

span.ant-radio + * {
  font-size: 0.95vw;
  font-weight: 300;
  letter-spacing: 0.075vw;
}

.fax-out-number-fields {
  display: flex;
  width: 100%;
}

.fax-out-number-fields p,
.fax-out-form-box input[type="text"] {
  width: 41%;
  margin-right: 9%;
}

.greyed-out {
  cursor: not-allowed;
  opacity: 0.35;
}

.fax-out-form-box input[type="text"] {
  height: fit-content;
}

.fax-out-form-box button[type="submit"] {
  margin-top: 1vw;
  font-weight: 400;
  text-transform: capitalize;
}

.fax-out-form-box h3 {
  font-size: 1.65vw;
  font-weight: 300;
  letter-spacing: 0.2vw;
  color: rgb(64, 168, 164);
  position: absolute;
  animation: bounceUpDownFaxOutFormBoxH3 0.5s ease-in-out forwards;
}

@keyframes bounceUpDownFaxOutFormBoxH3 {
  0%,
  100% {
    font-size: 1.65vw;
  }

  50% {
    font-size: 1.75vw;
  }
}

.fax-out-form-box .red-alert {
  color: tomato;
}

.hidePatienAbdomenPainArea {
  display: none;
}

.showPatienAbdomenPainArea {
  display: block;
}

.showPatienAbdomenPainArea label,
.black-label label {
  color: #000 !important;
}

/* ==== */
/* Report correction form */
/* ==== */

.report-correction-modal {
  position: absolute;
  width: 80%;
  height: 80vh;
  background-color: white;
  border-radius: 1vw;
  margin-top: 5vh;
  top: 0%;
  right: 12%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  overflow: hidden;
  z-index: 1;
}

.report-correction-modal .form-container {
  width: 100%;
  height: 85%;
  overflow-y: auto;
}

.report-correction-modal .form-action-container {
  width: 100%;
  height: 15%;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  display: flex;
  padding: 1.35vw 3.75%;
  justify-content: flex-end;
}

.report-correction-modal .btn-action-submit {
  background-color: rgb(61, 168, 164);
  color: white;
  border: none;
  cursor: pointer;
  padding: 0 1.35vw;
  transform: all 0.35s ease-in-out;
}

.report-correction-modal .btn-action-cancel {
  background-color: #232323;
  color: white;
  margin-right: 1vw;
  padding: 0 1.35vw;
  transform: all 0.35s ease-in-out;
  cursor: pointer;
}

.report-correction-modal button:disabled {
  opacity: 0.7;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

/*== start of code for tooltips ==*/
.tool {
  cursor: help;
  position: relative;
}

/*== common styles for both parts of tool tip ==*/
.tool::before,
.tool::after {
  left: 50%;
  opacity: 0;
  position: absolute;
  z-index: -100;
}

.tool:hover::before,
.tool:focus::before,
.tool:hover::after,
.tool:focus::after {
  opacity: 1;
  transform: scale(1) translateY(0);
  z-index: 100;
}

/*== pointer tip ==*/
.tool::before {
  border-style: solid;
  border-width: 1em 0.75em 0 0.75em;
  border-color: #3e474f transparent transparent transparent;
  bottom: 100%;
  content: "";
  margin-left: -0.5em;
  transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26),
    opacity 0.65s 0.5s;
  transform: scale(0.6) translateY(-90%);
}

.tool:hover::before,
.tool:focus::before {
  transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
}

/*== speech bubble ==*/
.tool::after {
  background: #3e474f;
  border-radius: 0.25em;
  bottom: 180%;
  color: #edeff0;
  content: attr(data-tip);
  margin-left: -8.75em;
  padding: 1em;
  transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
  transform: scale(0.6) translateY(50%);
  width: 17.5em;
}

.tool:hover::after,
.tool:focus::after {
  transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26);
}

@media (max-width: 760px) {
  .tool::after {
    font-size: 0.75em;
    margin-left: -5em;
    width: 10em;
  }
}

.dataTables_filter {
  display: none;
}

/* Loader */

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #1c87c9;
  border-radius: 50%;
  content: "";
  height: 40px;
  width: 40px;
  position: absolute;
  top: 10%;
  left: 10%;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}
