.theme-purple .wizard .steps .current a,
.theme-purple .profile-page .profile-sub-header .box-list ul li a:hover,
.theme-purple .fc .fc-toolbar,
.theme-purple .blog-page .single-blog-post .img-holder .date-box,
.theme-purple .menu-app li ul li a:hover,
.theme-purple .card .header .header-dropdown ul li a:hover {
	background: linear-gradient(45deg, #8f78db, #ab9ae5);
}
.theme-purple .navbar-brand {
	color: #fff;
}
.theme-purple .navbar-brand:hover {
	color: #fff;
}
.theme-purple .navbar-brand:active {
	color: #fff;
}
.theme-purple .navbar-brand:focus {
	color: #fff;
}
.theme-purple .nav > li > a {
	color: #fff;
}
.theme-purple .nav > li > a:hover {
	background: transparent;
}
.theme-purple .nav > li > a:focus {
	background: transparent;
}
.theme-purple .nav .open > a {
	background: transparent;
}
.theme-purple .nav .open > a:hover {
	background: #8f78db;
}
.theme-purple .nav .open > a:focus {
	background: transparent;
}
.theme-purple .bars {
	color: #fff;
}
.theme-purple .sidebar .menu .list li.active.open > a {
	background: linear-gradient(45deg, #8f78db, #ab9ae5);
	color: #fff;
}
.theme-purple .sidebar .menu .list li a:hover {
	color: #8f78db;
}
.theme-purple .sidebar .menu .list li.active >:first-child i,
.theme-purple .sidebar .menu .list li.active >:first-child span {
	color: #fff;
}
.theme-purple .sidebar .menu .list .toggled {
	background: #353d44;
	color: #fff;
}
.theme-purple .sidebar .menu .list .ml-menu {
	background: transparent;
}
.theme-purple .nav-tabs>li>a:before{
	border-bottom: 2px solid #8f78db;
}
.theme-purple .notify .heartbit {
	border-color: #ab9ae5;
}
.theme-purple .notify .point {
	background-color: #ab9ae5;
}
.theme-purple .chat .chat-history .other-message {
	background: #8f78db;
	color: #fff;
}
.theme-purple .chat .chat-history .other-message:after {
	border-bottom-color: #8f78db;
}
.theme-purple .cd-horizontal-timeline .filling-line {
	background-color: #8f78db;
}
.theme-purple .cd-horizontal-timeline .events a.selected::after,
.theme-purple .no-touch .cd-horizontal-timeline .events a:hover::after {
	background-color: #ab9ae5;
	border-color: #8f78db;
}
.theme-purple .cd-horizontal-timeline .events a.older-event::after,
.theme-purple .no-touch .cd-timeline-navigation a:hover {
	background-color: #8f78db;
	border-color: #8f78db;
}
.theme-purple .nav > li > a.active{border-bottom: 2px solid #8f78db !important;}
/*====== theme-blue ========*/

.theme-blue .wizard .steps .current a,
.theme-blue .profile-page .profile-sub-header .box-list ul li a:hover,
.theme-blue .fc .fc-toolbar,
.theme-blue .blog-page .single-blog-post .img-holder .date-box,
.theme-blue .menu-app li ul li a:hover,
.theme-blue .card .header .header-dropdown ul li a:hover {
	background: linear-gradient(45deg, #67d1fe, #479ef5);
}
.theme-blue .navbar-brand {
	color: #fff;
}
.theme-blue .navbar-brand:hover {
	color: #fff;
}
.theme-blue .navbar-brand:active {
	color: #fff;
}
.theme-blue .navbar-brand:focus {
	color: #fff;
}
.theme-blue .nav > li > a {
	color: #fff;
}
.theme-blue .nav > li > a:hover {
	background: transparent;
}
.theme-blue .nav > li > a:focus {
	background: transparent;
}
.theme-blue .nav .open > a {
	background: transparent;
}
.theme-blue .nav .open > a:hover {
	background: #479ef5;
}
.theme-blue .nav .open > a:focus {
	background: transparent;
}
.theme-blue .bars {
	color: #fff;
}
.theme-blue .sidebar .menu .list li.active.open > a {
	background: linear-gradient(45deg, #67d1fe, #479ef5);
	color: #fff;
}
.theme-blue .sidebar .menu .list li a:hover {
	color: #67d1fe;
}
.theme-blue .sidebar .menu .list li.active >:first-child i,
.theme-blue .sidebar .menu .list li.active >:first-child span {
	color: #fff;
}
.theme-blue .sidebar .menu .list .toggled {
	background: #353d44;
	color: #fff;
}
.theme-blue .sidebar .menu .list .ml-menu {
	background: transparent;
}
.theme-blue .nav-tabs>li>a:before{
	border-bottom: 2px solid #479ef5;
}
.theme-blue .notify .heartbit {
	border-color: #67d1fe;
}
.theme-blue .notify .point {
	background-color: #67d1fe;
}
.theme-blue .chat .chat-history .other-message {
	background: #479ef5;
	color: #fff;
}
.theme-blue .chat .chat-history .other-message:after {
	border-bottom-color: #479ef5;
}
.theme-blue .cd-horizontal-timeline .filling-line {
	background-color: #479ef5;
}
.theme-blue .cd-horizontal-timeline .events a.selected::after,
.theme-blue .no-touch .cd-horizontal-timeline .events a:hover::after {
	background-color: #67d1fe;
	border-color: #479ef5;
}
.theme-blue .cd-horizontal-timeline .events a.older-event::after,
.theme-blue .no-touch .cd-timeline-navigation a:hover {
	background-color: #479ef5;
	border-color: #479ef5;
}
.theme-blue .nav > li > a.active{border-bottom: 2px solid #479ef5 !important;}
/*====== theme-cyan ========*/

.theme-cyan .wizard .steps .current a,
.theme-cyan .profile-page .profile-sub-header .box-list ul li a:hover,
.theme-cyan .fc .fc-toolbar,
.theme-cyan .blog-page .single-blog-post .img-holder .date-box,
.theme-cyan .menu-app li ul li a:hover,
.theme-cyan .card .header .header-dropdown ul li a:hover {
	background:#fff;
	/* linear-gradient(45deg, #49cdd0, #ab9ae5); */
}
.theme-cyan .navbar-brand {
	color: #fff;
}
.theme-cyan .navbar-brand:hover {
	color: #fff;
}
.theme-cyan .navbar-brand:active {
	color: #fff;
}
.theme-cyan .navbar-brand:focus {
	color: #fff;
}
.theme-cyan .nav > li > a {
	color: #000206;
}
.theme-cyan .nav > li > a:hover {
	background: transparent;
}
.theme-cyan .nav > li > a:focus {
	background: transparent;
}
.theme-cyan .nav .open > a {
	background: transparent;
}
.theme-cyan .nav .open > a:hover {
	background: #49cdd0;
}
.theme-cyan .nav .open > a:focus {
	background: transparent;
}
.theme-cyan .bars {
	color: #fff;
}
.theme-cyan .sidebar .menu .list li.active.open > a {
	background: linear-gradient(45deg, #49cdd0, #ab9ae5);
	color: #fff;
}
.theme-cyan .sidebar .menu .list li a:hover {
	color: #45e5c3;
}
.theme-cyan .sidebar .menu .list li.active >:first-child i,
.theme-cyan .sidebar .menu .list li.active >:first-child span {
	color: #fff;
}
.theme-cyan .sidebar .menu .list .toggled {
	background: #353d44;
	color: #fff;
}
.theme-cyan .sidebar .menu .list .ml-menu {
	background: transparent;
}
.theme-cyan .nav-tabs>li>a:before{
	border-bottom: 2px solid #49cdd0;
}
.theme-cyan .notify .heartbit {
	border-color: #45e5c3;
}
.theme-cyan .notify .point {
	background-color: #45e5c3;
}
.theme-cyan .chat .chat-history .other-message {
	background: #49cdd0;
	color: #fff;
}
.theme-cyan .chat .chat-history .other-message:after {
	border-bottom-color: #49cdd0;
}
.theme-cyan .cd-horizontal-timeline .filling-line {
	background-color: #49cdd0;
}
.theme-cyan .cd-horizontal-timeline .events a.selected::after,
.theme-cyan .no-touch .cd-horizontal-timeline .events a:hover::after {
	background-color: #45e5c3;
	border-color: #49cdd0;
}
.theme-cyan .cd-horizontal-timeline .events a.older-event::after,
.theme-cyan .no-touch .cd-timeline-navigation a:hover {
	background-color: #49cdd0;
	border-color: #49cdd0;
}
.theme-cyan .nav > li > a.active{border-bottom: 2px solid #49cdd0 !important;}
/*====== theme-green ========*/

.theme-green .wizard .steps .current a,
.theme-green .profile-page .profile-sub-header .box-list ul li a:hover,
.theme-green .fc .fc-toolbar,
.theme-green .blog-page .single-blog-post .img-holder .date-box,
.theme-green .menu-app li ul li a:hover,
.theme-green .card .header .header-dropdown ul li a:hover {
	background: linear-gradient(45deg, #40b988, #5edb8c);
}
.theme-green .navbar-brand {
	color: #fff;
}
.theme-green .navbar-brand:hover {
	color: #fff;
}
.theme-green .navbar-brand:active {
	color: #fff;
}
.theme-green .navbar-brand:focus {
	color: #fff;
}
.theme-green .nav > li > a {
	color: #fff;
}
.theme-green .nav > li > a:hover {
	background: transparent;
}
.theme-green .nav > li > a:focus {
	background: transparent;
}
.theme-green .nav .open > a {
	background: transparent;
}
.theme-green .nav .open > a:hover {
	background: #40b988;
}
.theme-green .nav .open > a:focus {
	background: transparent;
}
.theme-green .bars {
	color: #fff;
}
.theme-green .sidebar .menu .list li.active.open > a {
	background: linear-gradient(45deg, #40b988, #5edb8c);
	color: #fff;
}
.theme-green .sidebar .menu .list li a:hover {
	color: #40b988;
}
.theme-green .sidebar .menu .list li.active >:first-child i,
.theme-green .sidebar .menu .list li.active >:first-child span {
	color: #fff;
}
.theme-green .sidebar .menu .list .toggled {
	background: #353d44;
	color: #fff;
}
.theme-green .sidebar .menu .list .ml-menu {
	background: transparent;
}
.theme-green .nav-tabs>li>a:before {
	border-bottom: 2px solid #40b988;
}
.theme-green .notify .heartbit {
	border-color: #5edb8c;
}
.theme-green .notify .point {
	background-color: #5edb8c;
}
.theme-green .chat .chat-history .other-message {
	background: #40b988;
	color: #fff;
}
.theme-green .chat .chat-history .other-message:after {
	border-bottom-color: #40b988;
}
.theme-green .cd-horizontal-timeline .filling-line {
	background-color: #40b988;
}
.theme-green .cd-horizontal-timeline .events a.selected::after,
.theme-green .no-touch .cd-horizontal-timeline .events a:hover::after {
	background-color: #5edb8c;
	border-color: #40b988;
}
.theme-green .cd-horizontal-timeline .events a.older-event::after,
.theme-green .no-touch .cd-timeline-navigation a:hover {
	background-color: #40b988;
	border-color: #40b988;
}
.theme-green .nav > li > a.active{border-bottom: 2px solid #40b988 !important;}
/*====== theme-blush ========*/

.theme-blush .wizard .steps .current a,
.theme-blush .profile-page .profile-sub-header .box-list ul li a:hover,
.theme-blush .fc .fc-toolbar,
.theme-blush .blog-page .single-blog-post .img-holder .date-box,
.theme-blush .menu-app li ul li a:hover,
.theme-blush .card .header .header-dropdown ul li a:hover {
	background: linear-gradient(45deg, #dd5e89, #f7bb97);
}
.theme-blush .navbar-brand {
	color: #fff;
}
.theme-blush .navbar-brand:hover {
	color: #fff;
}
.theme-blush .navbar-brand:active {
	color: #fff;
}
.theme-blush .navbar-brand:focus {
	color: #fff;
}
.theme-blush .nav > li > a {
	color: #fff;
}
.theme-blush .nav > li > a:hover {
	background: transparent;
}
.theme-blush .nav > li > a:focus {
	background: transparent;
}
.theme-blush .nav .open > a {
	background: transparent;
}
.theme-blush .nav .open > a:hover {
	background: #dd5e89;
}
.theme-blush .nav .open > a:focus {
	background: transparent;
}
.theme-blush .bars {
	color: #fff;
}
.theme-blush .sidebar .menu .list li.active.open > a {
	background: linear-gradient(45deg, #dd5e89, #f7bb97);
	color: #fff;
}
.theme-blush .sidebar .menu .list li a:hover {
	color: #dd5e89;
}
.theme-blush .sidebar .menu .list li.active >:first-child i,
.theme-blush .sidebar .menu .list li.active >:first-child span {
	color: #fff;
}
.theme-blush .sidebar .menu .list .toggled {
	background: #353d44;
	color: #fff;
}
.theme-blush .sidebar .menu .list .ml-menu {
	background: transparent;
}
.theme-blush .nav-tabs>li>a:before {
	border-bottom: 2px solid #dd5e89;
}
.theme-blush .notify .heartbit {
	border-color: #f7bb97;
}
.theme-blush .notify .point {
	background-color: #f7bb97;
}
.theme-blush .chat .chat-history .other-message {
	background: #dd5e89;
	color: #fff;
}
.theme-blush .chat .chat-history .other-message:after {
	border-bottom-color: #dd5e89;
}
.theme-blush .cd-horizontal-timeline .filling-line {
	background-color: #dd5e89;
}
.theme-blush .cd-horizontal-timeline .events a.selected::after,
.theme-blush .no-touch .cd-horizontal-timeline .events a:hover::after {
	background-color: #f7bb97;
	border-color: #dd5e89;
}
.theme-blush .cd-horizontal-timeline .events a.older-event::after,
.theme-blush .no-touch .cd-timeline-navigation a:hover {
	background-color: #dd5e89;
	border-color: #dd5e89;
}
.theme-blush .nav > li > a.active{border-bottom: 2px solid #dd5e89 !important;}
/*====== Theme light ========*/

.light,
.light .sidebar .menu,
.light.ls-closed .sidebar {
	background: #f0f3f5;
}
.light .sidebar .menu .user-info {
	color: #30373e;
}
.light .sidebar .menu .list .header {
	color: #999;
	border-bottom: 1px solid #e8e8e8;
}
.light .card {
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.light .navbar-brand {
	color: #30373e;
}
.light .navbar-brand:hover {
	color: #30373e;
}
.light .navbar-brand:active {
	color: #30373e;
}
.light .navbar-brand:focus {
	color: #30373e;
}
.light .nav > li > a {
	color: #30373e;
}
.light .nav li a i {
	font-weight: 600;
}
.light .nav > li > a:hover {
	background: transparent;
}
.light .nav > li > a:focus {
	background: transparent;
}
.light .nav .open > a {
	background: transparent;
}
.light .nav .open > a:focus {
	background: transparent;
}
.light .bars {
	color: #30373e;
}
.light .sidebar .menu .list .ml-menu li a,
.light .sidebar .menu .list a {
	color: #444;
}
.light .sidebar .menu .list li a:hover {
	color: #30373e;
}
.light .sidebar .menu .list li.active >:first-child i,
.light .sidebar .menu .list li.active >:first-child span {
	color: #fff;
}
.light .sidebar .menu .list .toggled {
	background: #e9eaea;
	color: #30373e;
}
.light section.content {
	padding: 0;
	margin: 0 15px 15px 265px;
}
.light.ls-closed section.content {
	margin: 55px 0px 15px 0px;
}
.light.rtl section.content {
	margin: 55px 265px 15px 15px;
}
.light .block-header {
	margin: 0;
}
.theme-light-dark li {
	display: inline-block;
}
.theme-light-dark li:hover {
	cursor: pointer;
}
.theme-light-dark .t-dark,
.theme-light-dark .t-light {
	padding: 5px 15px;
	display: inline-block;
	border-radius: 3px;
}
.theme-light-dark .t-dark {
	background: #30373e;
	border: 1px solid #30373e;
	color: #fff;
}
.theme-light-dark .t-light {
	background: #fff;
	border: 1px solid #30373e;
}
.light .email-page,
.light .profile-page {
	margin-top: 55px !important;
}
.navbar .navbar-right li, 
.navbar .navbar-right li > a {
	color: #8c8c8c;
}
.navbar .navbar-right.custom-font li, 
.navbar .navbar-right.custom-font li > a {
	color: #8c8c8c; font-size: 12px;
}
.navbar .navbar-right.custom-font.navbar-nav>li>a {
    padding: 4px 8px 3px 8px;
    margin-top: 5px;
    margin-left: 5px;
}
.navbar .navbar-right.custom-font {
    float: right;
    padding: 4px;
}
li.icon-color a i {
	color: #5da6a2;
}
.navbar-nav.navbar-right.custom-font {
	margin-right: 10px;
}
.navbar.nav-custom {position: relative;} 
.navbar-light .navbar-toggler {display: none;}
.footerarea {z-index: 8;}
.custom-contactus-area {
	background: #3DA8A4;
	min-height: 100px;
	height: auto;
	padding: 10px;
	color: #fff;
}
.custom-contactus-area h5 {
	margin-bottom: 3px;
}
.content.home .container-fluid {
	min-height: 300px;
}
.middle-footer-area {
	border-top: 1px solid #808080;
	margin-top: 20px;
}
.custom-left-area {
	width: 300px;
	height: auto;
	margin: 0px auto;
	padding: 5px;
}
.custom-left-area img.footer-logo {
	width: 220px;
    height: auto;
}
.custom-left-area p {
	margin-bottom: 5px;
	line-height: 14px;
}
.footer-title {
	color: #3DA8A4;
	font-size: 11px;
	text-transform: uppercase;
	font-weight: bold;
	line-height: 14px;
}
.footer-text {
	color: #3a3a3b;
	font-size: 11px;
	line-height: 14px;
}
.footer-right p {
	margin-bottom: 5px;
}
.list-unstyled.company li a {
	color: #3a3a3b;
	font-size: 11px;
	line-height: 18px;
}
.footer-right ul.navbar-nav.navbar-right.custom-font {
	margin-left: -17px;
}
.custom-margin-bottom {
	margin-bottom: 0px;
	padding-bottom: 0px;
}
.custom-margin-top {
	margin-top: 0px;
}
.bottom-footer-area {
	background: #333333;
	width: 100%;
	height: auto;
	margin: 0px;
	padding: 10px;
}
.bottom-footer-area p {
	color: #fff;
	font-size: 13px;
	text-align: left;
	line-height: 20px;
	margin-bottom: 5px;
    padding-top: 10px;
}
.bottom-footer-area p a {
	text-decoration: underline;
	color: #3DA8A4;
}
.breadcrumb-area {
	background: #3DA8A4;
	height: auto;
	width: 100%;
	padding: 10px;
	margin: 80px 0px 0px;
	position: relative;
	z-index: 6;
}
.breadcrumb {
	padding: .75rem 0rem 0px;
}
.breadcrumb.breadcrumb-col-teal p,
.breadcrumb-col-teal li,
.breadcrumb-col-teal li a {
	color: #fff !important;
	font-size: 18px;
}
.breadcrumb>li+li:before {
	color: #fff;
	font-size: 18px;
}
.provider_order .demo-checkbox label, .provider_order .demo-radio-button label {
    min-width: 55px;
}
.provider_order .checkbox label, .provider_order .radio label, .provider_order label {
    font-size: 14px;
    line-height: 1.428571429;
    color: #000206;
    font-weight: 400;
}
.formarea {
	width: 100%;
	height: auto;
	padding: 0px;
	margin: 0px;	
}
.formarea.showForm {
 	display: block;
}
.formarea.hideForm {
	display: none;
}
.panel-group .panel .panel-heading a {
	position: relative;
}
.panel-group .panel .panel-heading a:before {
    content: "";
	border-top: 10px solid #fff;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    position: absolute;
    top: 18px;
    right: 20px;
}
.form-group.required .form-label:after {
	content:"*";color:red;
}
.demo-checkbox.required label.form-label:before {
	content:"*";color:red;
}
.react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: 0px solid gray !important;
}
.hidePatienAbdomenPainArea {
	display: none;
}
.showPatienAbdomenPainArea {
	display: block;
}

.form-group.required .form-label.gender:after,
.form-group.required .form-label.menopausalStatus:after
{
    content: "";
    color: transparent;
}
.hidediv {display: none;}
.showdiv {display: block;}
.center-div {
	width: 320px;
	height: 70px;
	padding: 0px;
	margin: 0px auto;
	text-align: center;
}
/*  2bade0 OVA */
/* 
div#KitShippingInfoForm  label,
div#KitShippingInfoForm label.form-label,
div#OtherICD10CodesForm  label,
div#OtherICD10CodesForm label.form-label,
div#KitShippingInfoOVAForm  label,
div#KitShippingInfoOVAForm label.form-label,
div#ClinicalInformationOVAForm  label,
div#ClinicalInformationOVAForm label.form-label,
div#DiagnosisCodes_ICD10CodesForm label,
div#DiagnosisCodes_ICD10CodesForm label.form-label,
div#ClinicalInformationForm label,
div#ClinicalInformationForm label.form-label,
div#DiagnosisCodes_ICD10CodesForm2 label,
div#DiagnosisCodes_ICD10CodesForm2 label.form-label {
	color: #2bade0;
}

div#KitShippingInfoForm [type="checkbox"].filled-in:not(:checked)+label:after,
div#OtherICD10CodesForm [type="checkbox"].filled-in:not(:checked)+label:after,
div#KitShippingInfoOVAForm [type="checkbox"].filled-in:not(:checked)+label:after,
div#ClinicalInformationOVAForm [type="checkbox"].filled-in:not(:checked)+label:after,
div#DiagnosisCodes_ICD10CodesForm2 [type="checkbox"].filled-in:not(:checked)+label:after,
div#DiagnosisCodes_ICD10CodesForm [type="checkbox"].filled-in:not(:checked)+label:after,
div#ClinicalInformationForm [type="checkbox"].filled-in:not(:checked)+label:after {    
    background-color: transparent;
    border: 2px solid #2bade0;
}

div#OtherICD10CodesForm [type="checkbox"].filled-in:checked+label:after,
div#KitShippingInfoForm [type="checkbox"].filled-in:checked+label:after,
div#KitShippingInfoOVAForm [type="checkbox"].filled-in:checked+label:after,
div#ClinicalInformationOVAForm [type="checkbox"].filled-in:checked+label:after,
div#DiagnosisCodes_ICD10CodesForm2 [type="checkbox"].filled-in:checked+label:after,
div#DiagnosisCodes_ICD10CodesForm [type="checkbox"].filled-in:checked+label:after,
div#ClinicalInformationForm [type="checkbox"].filled-in:checked+label:after  {    
    border: 2px solid #2bade0;
	background-color: #2bade0;
}
div#OtherICD10CodesForm .form-group .form-line,
div#KitShippingInfoForm .form-group .form-line,
div#KitShippingInfoOVAForm .form-group .form-line,
div#ClinicalInformationOVAForm .form-group .form-line,
div#DiagnosisCodes_ICD10CodesForm2 .form-group .form-line,
div#ClinicalInformationForm .form-group .form-line {   
    border-bottom: 1px solid #2bade0;
}
div#OtherICD10CodesForm .form-group .form-control,
div#KitShippingInfoForm .form-group .form-control,
div#KitShippingInfoOVAForm .form-group .form-control,
div#ClinicalInformationOVAForm .form-group .form-control,
div#DiagnosisCodes_ICD10CodesForm2 .form-group .form-control,
div#ClinicalInformationForm .form-group .form-control {
	background: #2bade0;
	padding-left: 4px;
    color: #fff;
}
div#KitShippingInfoForm .form-control::-moz-placeholder,
div#KitShippingInfoForm .form-group .form-control::-moz-placeholder,
div#OtherICD10CodesForm .form-control::-moz-placeholder,
div#OtherICD10CodesForm .form-group .form-control::-moz-placeholder,
div#KitShippingInfoOVAForm .form-control::-moz-placeholder,
div#KitShippingInfoOVAForm .form-group .form-control::-moz-placeholder,
div#ClinicalInformationOVAForm .form-control::-moz-placeholder,
div#ClinicalInformationOVAForm .form-group .form-control::-moz-placeholder,
div#DiagnosisCodes_ICD10CodesForm2 .form-control::-moz-placeholder,
div#DiagnosisCodes_ICD10CodesForm2 .form-group .form-control::-moz-placeholder,
div#ClinicalInformationForm .form-control::-moz-placeholder,
div#ClinicalInformationForm .form-group .form-control::-moz-placeholder {color:#fff;font-weight:400}

div#KitShippingInfoForm .form-control:-ms-input-placeholder,
div#KitShippingInfoForm .form-group .form-control:-ms-input-placeholder,
div#OtherICD10CodesForm .form-control:-ms-input-placeholder,
div#OtherICD10CodesForm .form-group .form-control:-ms-input-placeholder,
div#KitShippingInfoOVAForm .form-control:-ms-input-placeholder,
div#KitShippingInfoOVAForm .form-group .form-control:-ms-input-placeholder,
div#ClinicalInformationOVAForm .form-control:-ms-input-placeholder,
div#ClinicalInformationOVAForm .form-group .form-control:-ms-input-placeholder,
div#DiagnosisCodes_ICD10CodesForm2 .form-control:-ms-input-placeholder,
div#DiagnosisCodes_ICD10CodesForm2 .form-group .form-control:-ms-input-placeholder,
div#ClinicalInformationForm .form-control:-ms-input-placeholder,
div#ClinicalInformationForm .form-group .form-control:-ms-input-placeholder {color:#fff;font-weight:400}

div#KitShippingInfoForm .form-control::-webkit-input-placeholder,
div#KitShippingInfoForm .form-group .form-control::-webkit-input-placeholder,
div#OtherICD10CodesForm .form-control::-webkit-input-placeholder,
div#OtherICD10CodesForm .form-group .form-control::-webkit-input-placeholder,
div#KitShippingInfoOVAForm .form-control::-webkit-input-placeholder,
div#KitShippingInfoOVAForm .form-group .form-control::-webkit-input-placeholder,
div#ClinicalInformationOVAForm .form-control::-webkit-input-placeholder,
div#ClinicalInformationOVAForm .form-group .form-control::-webkit-input-placeholder,
div#DiagnosisCodes_ICD10CodesForm2 .form-control::-webkit-input-placeholder,
div#DiagnosisCodes_ICD10CodesForm2 .form-group .form-control::-webkit-input-placeholder,  
div#ClinicalInformationForm .form-control::-webkit-input-placeholder,
div#ClinicalInformationForm .form-group .form-control::-webkit-input-placeholder {color:#fff;font-weight:400}

div#ClinicalInformationForm [type="radio"]:not(:checked)+label:before, 
div#ClinicalInformationForm [type="radio"]:not(:checked)+label:after,
div#OtherICD10CodesForm [type="radio"]:not(:checked)+label:before, 
div#OtherICD10CodesForm [type="radio"]:not(:checked)+label:after,
div#KitShippingInfoOVAForm [type="radio"]:not(:checked)+label:before, 
div#KitShippingInfoOVAForm [type="radio"]:not(:checked)+label:after,
div#ClinicalInformationOVAForm [type="radio"]:not(:checked)+label:before, 
div#ClinicalInformationOVAForm [type="radio"]:not(:checked)+label:after,
div#DiagnosisCodes_ICD10CodesForm2 [type="radio"]:not(:checked)+label:before, 
div#DiagnosisCodes_ICD10CodesForm2 [type="radio"]:not(:checked)+label:after {
    border: 2px solid #2bade0;
}
div#ClinicalInformationForm [type="radio"]:checked+label:after, 
div#ClinicalInformationForm [type="radio"].with-gap:checked+label:after,
div#OtherICD10CodesForm [type="radio"]:checked+label:after, 
div#OtherICD10CodesForm [type="radio"].with-gap:checked+label:after,
div#KitShippingInfoOVAForm [type="radio"]:checked+label:after, 
div#KitShippingInfoOVAForm [type="radio"].with-gap:checked+label:after,
div#ClinicalInformationOVAForm [type="radio"]:checked+label:after, 
div#ClinicalInformationOVAForm [type="radio"].with-gap:checked+label:after,
div#DiagnosisCodes_ICD10CodesForm2 [type="radio"]:checked+label:after, 
div#DiagnosisCodes_ICD10CodesForm2 [type="radio"].with-gap:checked+label:after  {
    background-color: #2bade0;
    z-index: 0;
}
div#ClinicalInformationForm [type="radio"]:checked+label:after, 
div#ClinicalInformationForm [type="radio"].with-gap:checked+label:before, 
div#ClinicalInformationForm [type="radio"].with-gap:checked+label:after,
div#OtherICD10CodesForm [type="radio"]:checked+label:after, 
div#OtherICD10CodesForm [type="radio"].with-gap:checked+label:before, 
div#OtherICD10CodesForm [type="radio"].with-gap:checked+label:after,
div#KitShippingInfoOVAForm [type="radio"]:checked+label:after, 
div#KitShippingInfoOVAForm [type="radio"].with-gap:checked+label:before, 
div#KitShippingInfoOVAForm [type="radio"].with-gap:checked+label:after,
div#ClinicalInformationOVAForm [type="radio"]:checked+label:after, 
div#ClinicalInformationOVAForm [type="radio"].with-gap:checked+label:before, 
div#ClinicalInformationOVAForm [type="radio"].with-gap:checked+label:after,
div#DiagnosisCodes_ICD10CodesForm2 [type="radio"]:checked+label:after, 
div#DiagnosisCodes_ICD10CodesForm2 [type="radio"].with-gap:checked+label:before, 
div#DiagnosisCodes_ICD10CodesForm2 [type="radio"].with-gap:checked+label:after {
    border: 2px solid #2bade0;
}
div#OtherICD10CodesForm p {color: #2bade0;}


div#ClinicalInformationOVAForm .form-group.required .form-label.menopausalStatus:after ,
div#ClinicalInformationForm .form-group.required .form-label.menopausalStatus:after {    
    color: #2bade0;
} */

/* Carrier form */
/* 
div#ASPiRAGENETIXCarrierScreeningForm label,
div#ASPiRAGENETIXCarrierScreeningForm label.form-label,
div#DiagnosisCodes_ICD10CodesCarrierForm label,
div#DiagnosisCodes_ICD10CodesCarrierForm label.form-label,
div#FamilyHistoryForm label,
div#FamilyHistoryForm label.form-label,
div#KitShippingInfoCarrierForm label,
div#KitShippingInfoCarrierForm label.form-label {
	color: #652e8f;
}

div#ASPiRAGENETIXCarrierScreeningForm [type="checkbox"].filled-in:not(:checked)+label:after,
div#DiagnosisCodes_ICD10CodesCarrierForm [type="checkbox"].filled-in:not(:checked)+label:after,
div#FamilyHistoryForm [type="checkbox"].filled-in:not(:checked)+label:after,
div#KitShippingInfoCarrierForm [type="checkbox"].filled-in:not(:checked)+label:after {    
    background-color: transparent;
    border: 2px solid #652e8f;
}

div#ASPiRAGENETIXCarrierScreeningForm [type="checkbox"].filled-in:checked+label:after,
div#DiagnosisCodes_ICD10CodesCarrierForm [type="checkbox"].filled-in:checked+label:after,
div#FamilyHistoryForm [type="checkbox"].filled-in:checked+label:after,
div#KitShippingInfoCarrierForm [type="checkbox"].filled-in:checked+label:after {    
    border: 2px solid #652e8f;
	background-color: #652e8f;
}
div#ASPiRAGENETIXCarrierScreeningForm .form-group .form-line,
div#DiagnosisCodes_ICD10CodesCarrierForm .form-group .form-line,
div#FamilyHistoryForm .form-group .form-line,
div#KitShippingInfoCarrierForm .form-group .form-line {   
    border-bottom: 1px solid #652e8f;
}
div#ASPiRAGENETIXCarrierScreeningForm .form-group .form-control,
div#DiagnosisCodes_ICD10CodesCarrierForm .form-group .form-control,
div#FamilyHistoryForm .form-group .form-control,
div#KitShippingInfoCarrierForm .form-group .form-control {
	background: #652e8f;
	padding-left: 4px;
    color: #fff;
}
div#KitShippingInfoCarrierForm .form-control::-moz-placeholder,
div#KitShippingInfoCarrierForm .form-group .form-control::-moz-placeholder,
div#ASPiRAGENETIXCarrierScreeningForm .form-control::-moz-placeholder,
div#ASPiRAGENETIXCarrierScreeningForm .form-group .form-control::-moz-placeholder,
div#DiagnosisCodes_ICD10CodesCarrierForm .form-control::-moz-placeholder,
div#DiagnosisCodes_ICD10CodesCarrierForm .form-group .form-control::-moz-placeholder, 
div#FamilyHistoryForm .form-control::-moz-placeholder,
div#FamilyHistoryForm .form-group .form-control::-moz-placeholder {color:#fff;font-weight:400}

div#KitShippingInfoCarrierForm .form-control:-ms-input-placeholder,
div#KitShippingInfoCarrierForm .form-group .form-control:-ms-input-placeholder,
div#ASPiRAGENETIXCarrierScreeningForm .form-control:-ms-input-placeholder,
div#ASPiRAGENETIXCarrierScreeningForm .form-group .form-control:-ms-input-placeholder,
div#DiagnosisCodes_ICD10CodesCarrierForm .form-control:-ms-input-placeholder,
div#DiagnosisCodes_ICD10CodesCarrierForm .form-group .form-control:-ms-input-placeholder,
div#FamilyHistoryForm .form-control:-ms-input-placeholder,
div#FamilyHistoryForm .form-group .form-control:-ms-input-placeholder {color:#fff;font-weight:400}

div#KitShippingInfoCarrierForm .form-control::-webkit-input-placeholder,
div#KitShippingInfoCarrierForm .form-group .form-control::-webkit-input-placeholder,
div#ASPiRAGENETIXCarrierScreeningForm .form-control::-webkit-input-placeholder,
div#ASPiRAGENETIXCarrierScreeningForm .form-group .form-control::-webkit-input-placeholder, 
div#DiagnosisCodes_ICD10CodesCarrierForm .form-control::-webkit-input-placeholder,
div#DiagnosisCodes_ICD10CodesCarrierForm .form-group .form-control::-webkit-input-placeholder,
div#FamilyHistoryForm .form-control::-webkit-input-placeholder,
div#FamilyHistoryForm .form-group .form-control::-webkit-input-placeholder 
{color:#fff;font-weight:400}

div#KitShippingInfoCarrierForm [type="radio"]:not(:checked)+label:before, 
div#KitShippingInfoCarrierForm [type="radio"]:not(:checked)+label:after,
div#ASPiRAGENETIXCarrierScreeningForm [type="radio"]:not(:checked)+label:before, 
div#ASPiRAGENETIXCarrierScreeningForm [type="radio"]:not(:checked)+label:after,
div#DiagnosisCodes_ICD10CodesCarrierForm [type="radio"]:not(:checked)+label:before, 
div#DiagnosisCodes_ICD10CodesCarrierForm [type="radio"]:not(:checked)+label:after, 
div#FamilyHistoryForm [type="radio"]:not(:checked)+label:before, 
div#FamilyHistoryForm [type="radio"]:not(:checked)+label:after {
    border: 2px solid #652e8f;
}
div#KitShippingInfoCarrierForm [type="radio"]:checked+label:after, 
div#KitShippingInfoCarrierForm [type="radio"].with-gap:checked+label:after,
div#ASPiRAGENETIXCarrierScreeningForm [type="radio"]:checked+label:after, 
div#ASPiRAGENETIXCarrierScreeningForm [type="radio"].with-gap:checked+label:after,
div#DiagnosisCodes_ICD10CodesCarrierForm [type="radio"]:checked+label:after, 
div#DiagnosisCodes_ICD10CodesCarrierForm [type="radio"].with-gap:checked+label:after,
div#FamilyHistoryForm [type="radio"]:checked+label:after, 
div#FamilyHistoryForm [type="radio"].with-gap:checked+label:after {
    background-color: #652e8f;
    z-index: 0;
}
div#KitShippingInfoCarrierForm [type="radio"]:checked+label:after, 
div#KitShippingInfoCarrierForm [type="radio"].with-gap:checked+label:before, 
div#KitShippingInfoCarrierForm [type="radio"].with-gap:checked+label:after,
div#ASPiRAGENETIXCarrierScreeningForm [type="radio"]:checked+label:after, 
div#ASPiRAGENETIXCarrierScreeningForm [type="radio"].with-gap:checked+label:before, 
div#ASPiRAGENETIXCarrierScreeningForm [type="radio"].with-gap:checked+label:after,
div#DiagnosisCodes_ICD10CodesCarrierForm [type="radio"]:checked+label:after, 
div#DiagnosisCodes_ICD10CodesCarrierForm [type="radio"].with-gap:checked+label:before, 
div#DiagnosisCodes_ICD10CodesCarrierForm [type="radio"].with-gap:checked+label:after,
div#FamilyHistoryForm [type="radio"]:checked+label:after, 
div#FamilyHistoryForm [type="radio"].with-gap:checked+label:before, 
div#FamilyHistoryForm [type="radio"].with-gap:checked+label:after {
    border: 2px solid #652e8f;
}
div#ASPiRAGENETIXCarrierScreeningForm p {color: #652e8f; } */

/* HBOC 009144  006b2d */

/* div#HBOCClicnicalInfoForm label,
div#HBOCClicnicalInfoForm label.form-label,
div#KitShippingInfoHBOCForm2 label,
div#KitShippingInfoHBOCForm2 label.form-label,
div#HBOCClicnicalInfoForm2 label,
div#HBOCClicnicalInfoForm2 label.form-label,
div#KitShippingInfoHBOCForm label,
div#KitShippingInfoHBOCForm label.form-label,
div#HBOCFamilyHistoryInfoForm label,
div#HBOCFamilyHistoryInfoForm label.form-label,
div#HBOCFamilyHistoryInfoForm2 label,
div#HBOCFamilyHistoryInfoForm2 label.form-label,
div#DiagnosisCodes_ICD10CodesHBOCForm label,
div#DiagnosisCodes_ICD10CodesHBOCForm label.form-label,
div#ASPGENHereditaryBreastOvarianCancerForm label,
div#ASPGENHereditaryBreastOvarianCancerForm label.form-label,
div#ASPGENHereditaryBreastOvarianCancerForm2 label,
div#ASPGENHereditaryBreastOvarianCancerForm2 label.form-label {
	color: #006b2d;
}
div#HBOCClicnicalInfoForm [type="checkbox"].filled-in:not(:checked)+label:after,
div#KitShippingInfoHBOCForm2 [type="checkbox"].filled-in:not(:checked)+label:after,
div#HBOCClicnicalInfoForm2 [type="checkbox"].filled-in:not(:checked)+label:after,
div#KitShippingInfoHBOCForm [type="checkbox"].filled-in:not(:checked)+label:after,
div#HBOCFamilyHistoryInfoForm [type="checkbox"].filled-in:not(:checked)+label:after,
div#HBOCFamilyHistoryInfoForm2 [type="checkbox"].filled-in:not(:checked)+label:after,
div#DiagnosisCodes_ICD10CodesHBOCForm [type="checkbox"].filled-in:not(:checked)+label:after,
div#ASPGENHereditaryBreastOvarianCancerForm [type="checkbox"].filled-in:not(:checked)+label:after,
div#ASPGENHereditaryBreastOvarianCancerForm2 [type="checkbox"].filled-in:not(:checked)+label:after {    
    background-color: transparent;
    border: 2px solid #006b2d;
}
div#HBOCClicnicalInfoForm [type="checkbox"].filled-in:checked+label:after,
div#KitShippingInfoHBOCForm2 [type="checkbox"].filled-in:checked+label:after,
div#HBOCClicnicalInfoForm2 [type="checkbox"].filled-in:checked+label:after,
div#KitShippingInfoHBOCForm [type="checkbox"].filled-in:checked+label:after,
div#HBOCFamilyHistoryInfoForm [type="checkbox"].filled-in:checked+label:after,
div#HBOCFamilyHistoryInfoForm2 [type="checkbox"].filled-in:checked+label:after,
div#DiagnosisCodes_ICD10CodesHBOCForm [type="checkbox"].filled-in:checked+label:after,
div#ASPGENHereditaryBreastOvarianCancerForm [type="checkbox"].filled-in:checked+label:after,
div#ASPGENHereditaryBreastOvarianCancerForm2 [type="checkbox"].filled-in:checked+label:after {    
    border: 2px solid #006b2d;
	background-color: #006b2d;
}
div#HBOCClicnicalInfoForm .form-group .form-line,
div#KitShippingInfoHBOCForm2 .form-group .form-line,
div#HBOCClicnicalInfoForm2 .form-group .form-line,
div#HBOCClicnicalInfoForm2 .form-group .form-line,
div#KitShippingInfoHBOCForm .form-group .form-line,
div#KitShippingInfoHBOCForm .form-group .form-line,
div#HBOCFamilyHistoryInfoForm .form-group .form-line,
div#HBOCFamilyHistoryInfoForm .form-group .form-line,
div#HBOCFamilyHistoryInfoForm2 .form-group .form-line,
div#HBOCFamilyHistoryInfoForm2 .form-group .form-line,
div#DiagnosisCodes_ICD10CodesHBOCForm .form-group .form-line,
div#DiagnosisCodes_ICD10CodesHBOCForm .form-group .form-line,
div#ASPGENHereditaryBreastOvarianCancerForm .form-group .form-line,
div#ASPGENHereditaryBreastOvarianCancerForm .form-group .form-line,
div#ASPGENHereditaryBreastOvarianCancerForm2 .form-group .form-line,
div#ASPGENHereditaryBreastOvarianCancerForm2 .form-group .form-line  {   
    border-bottom: 1px solid #006b2d;
}
div#HBOCClicnicalInfoForm .form-group .form-control,
div#KitShippingInfoHBOCForm2 .form-group .form-control,
div#HBOCClicnicalInfoForm2 .form-group .form-control,
div#HBOCClicnicalInfoForm2 .form-group .form-control,
div#KitShippingInfoHBOCForm .form-group .form-control,
div#KitShippingInfoHBOCForm .form-group .form-control,
div#HBOCFamilyHistoryInfoForm .form-group .form-control,
div#HBOCFamilyHistoryInfoForm .form-group .form-control,
div#HBOCFamilyHistoryInfoForm2 .form-group .form-control,
div#HBOCFamilyHistoryInfoForm2 .form-group .form-control,
div#DiagnosisCodes_ICD10CodesHBOCForm .form-group .form-control,
div#DiagnosisCodes_ICD10CodesHBOCForm .form-group .form-control,
div#ASPGENHereditaryBreastOvarianCancerForm .form-group .form-control,
div#ASPGENHereditaryBreastOvarianCancerForm .form-group .form-control,
div#ASPGENHereditaryBreastOvarianCancerForm2 .form-group .form-control,
div#ASPGENHereditaryBreastOvarianCancerForm2 .form-group .form-control {
	background: #006b2d;
	padding-left: 4px;
    color: #fff;
}
div#HBOCClicnicalInfoForm .form-control::-moz-placeholder,
div#HBOCClicnicalInfoForm .form-group .form-control::-moz-placeholder,
div#KitShippingInfoHBOCForm2 .form-control::-moz-placeholder,
div#KitShippingInfoHBOCForm2 .form-group .form-control::-moz-placeholder,
div#HBOCClicnicalInfoForm2 .form-control::-moz-placeholder,
div#HBOCClicnicalInfoForm2 .form-group .form-control::-moz-placeholder,
div#KitShippingInfoHBOCForm .form-control::-moz-placeholder,
div#KitShippingInfoHBOCForm .form-group .form-control::-moz-placeholder,
div#HBOCFamilyHistoryInfoForm .form-control::-moz-placeholder,
div#HBOCFamilyHistoryInfoForm .form-group .form-control::-moz-placeholder,
div#HBOCFamilyHistoryInfoForm2 .form-control::-moz-placeholder,
div#HBOCFamilyHistoryInfoForm2 .form-group .form-control::-moz-placeholder,
div#DiagnosisCodes_ICD10CodesHBOCForm .form-control::-moz-placeholder,
div#DiagnosisCodes_ICD10CodesHBOCForm .form-group .form-control::-moz-placeholder,
div#ASPGENHereditaryBreastOvarianCancerForm .form-control::-moz-placeholder,
div#ASPGENHereditaryBreastOvarianCancerForm .form-group .form-control::-moz-placeholder,
div#ASPGENHereditaryBreastOvarianCancerForm2 .form-control::-moz-placeholder,
div#ASPGENHereditaryBreastOvarianCancerForm2 .form-group .form-control::-moz-placeholder {color:#fff;font-weight:400}

div#HBOCClicnicalInfoForm .form-control:-ms-input-placeholder,
div#HBOCClicnicalInfoForm .form-group .form-control:-ms-input-placeholder,
div#KitShippingInfoHBOCForm2 .form-control:-ms-input-placeholder,
div#KitShippingInfoHBOCForm2 .form-group .form-control:-ms-input-placeholder,
div#HBOCClicnicalInfoForm2 .form-control:-ms-input-placeholder,
div#HBOCClicnicalInfoForm2 .form-group .form-control:-ms-input-placeholder,
div#KitShippingInfoHBOCForm .form-control:-ms-input-placeholder,
div#KitShippingInfoHBOCForm .form-group .form-control:-ms-input-placeholder,
div#HBOCFamilyHistoryInfoForm .form-control:-ms-input-placeholder,
div#HBOCFamilyHistoryInfoForm .form-group .form-control:-ms-input-placeholder,
div#HBOCFamilyHistoryInfoForm2 .form-control:-ms-input-placeholder,
div#HBOCFamilyHistoryInfoForm2 .form-group .form-control:-ms-input-placeholder,
div#DiagnosisCodes_ICD10CodesHBOCForm .form-control:-ms-input-placeholder,
div#DiagnosisCodes_ICD10CodesHBOCForm .form-group .form-control:-ms-input-placeholder,
div#ASPGENHereditaryBreastOvarianCancerForm .form-control:-ms-input-placeholder,
div#ASPGENHereditaryBreastOvarianCancerForm .form-group .form-control:-ms-input-placeholder,
div#ASPGENHereditaryBreastOvarianCancerForm2 .form-control:-ms-input-placeholder,
div#ASPGENHereditaryBreastOvarianCancerForm2 .form-group .form-control:-ms-input-placeholder {color:#fff;font-weight:400}

div#HBOCClicnicalInfoForm .form-control::-webkit-input-placeholder,
div#HBOCClicnicalInfoForm .form-group .form-control::-webkit-input-placeholder,
div#KitShippingInfoHBOCForm2 .form-control::-webkit-input-placeholder,
div#KitShippingInfoHBOCForm2 .form-group .form-control::-webkit-input-placeholder,
div#HBOCClicnicalInfoForm2 .form-control::-webkit-input-placeholder,
div#HBOCClicnicalInfoForm2 .form-group .form-control::-webkit-input-placeholder,
div#KitShippingInfoHBOCForm .form-control::-webkit-input-placeholder,
div#KitShippingInfoHBOCForm .form-group .form-control::-webkit-input-placeholder,
div#HBOCFamilyHistoryInfoForm .form-control::-webkit-input-placeholder,
div#HBOCFamilyHistoryInfoForm .form-group .form-control::-webkit-input-placeholder,
div#HBOCFamilyHistoryInfoForm2 .form-control::-webkit-input-placeholder,
div#HBOCFamilyHistoryInfoForm2 .form-group .form-control::-webkit-input-placeholder,
div#DiagnosisCodes_ICD10CodesHBOCForm .form-control::-webkit-input-placeholder,
div#DiagnosisCodes_ICD10CodesHBOCForm .form-group .form-control::-webkit-input-placeholder,
div#ASPGENHereditaryBreastOvarianCancerForm .form-control::-webkit-input-placeholder,
div#ASPGENHereditaryBreastOvarianCancerForm .form-group .form-control::-webkit-input-placeholder,
div#ASPGENHereditaryBreastOvarianCancerForm2 .form-control::-webkit-input-placeholder,
div#ASPGENHereditaryBreastOvarianCancerForm2 .form-group .form-control::-webkit-input-placeholder {color:#fff;font-weight:400}

div#HBOCClicnicalInfoForm [type="radio"]:not(:checked)+label:before, 
div#HBOCClicnicalInfoForm [type="radio"]:not(:checked)+label:after,
div#KitShippingInfoHBOCForm2 [type="radio"]:not(:checked)+label:before, 
div#KitShippingInfoHBOCForm2 [type="radio"]:not(:checked)+label:after,
div#HBOCClicnicalInfoForm2 [type="radio"]:not(:checked)+label:before, 
div#HBOCClicnicalInfoForm2 [type="radio"]:not(:checked)+label:after,
div#KitShippingInfoHBOCForm [type="radio"]:not(:checked)+label:before, 
div#KitShippingInfoHBOCForm [type="radio"]:not(:checked)+label:after,
div#HBOCFamilyHistoryInfoForm [type="radio"]:not(:checked)+label:before, 
div#HBOCFamilyHistoryInfoForm [type="radio"]:not(:checked)+label:after,
div#HBOCFamilyHistoryInfoForm2 [type="radio"]:not(:checked)+label:before, 
div#HBOCFamilyHistoryInfoForm2 [type="radio"]:not(:checked)+label:after,
div#DiagnosisCodes_ICD10CodesHBOCForm [type="radio"]:not(:checked)+label:before, 
div#DiagnosisCodes_ICD10CodesHBOCForm [type="radio"]:not(:checked)+label:after,
div#ASPGENHereditaryBreastOvarianCancerForm [type="radio"]:not(:checked)+label:before, 
div#ASPGENHereditaryBreastOvarianCancerForm [type="radio"]:not(:checked)+label:after,
div#ASPGENHereditaryBreastOvarianCancerForm2 [type="radio"]:not(:checked)+label:before, 
div#ASPGENHereditaryBreastOvarianCancerForm2 [type="radio"]:not(:checked)+label:after {
    border: 2px solid #006b2d;
}

div#HBOCClicnicalInfoForm [type="radio"]:checked+label:after, 
div#HBOCClicnicalInfoForm [type="radio"].with-gap:checked+label:after,
div#KitShippingInfoHBOCForm2 [type="radio"]:checked+label:after, 
div#KitShippingInfoHBOCForm2 [type="radio"].with-gap:checked+label:after,
div#HBOCClicnicalInfoForm2 [type="radio"]:checked+label:after, 
div#HBOCClicnicalInfoForm2 [type="radio"].with-gap:checked+label:after,
div#KitShippingInfoHBOCForm [type="radio"]:checked+label:after, 
div#KitShippingInfoHBOCForm [type="radio"].with-gap:checked+label:after,
div#HBOCFamilyHistoryInfoForm [type="radio"]:checked+label:after, 
div#HBOCFamilyHistoryInfoForm [type="radio"].with-gap:checked+label:after,
div#HBOCFamilyHistoryInfoForm2 [type="radio"]:checked+label:after, 
div#HBOCFamilyHistoryInfoForm2 [type="radio"].with-gap:checked+label:after,
div#DiagnosisCodes_ICD10CodesHBOCForm [type="radio"]:checked+label:after, 
div#DiagnosisCodes_ICD10CodesHBOCForm [type="radio"].with-gap:checked+label:after,
div#ASPGENHereditaryBreastOvarianCancerForm [type="radio"]:checked+label:after, 
div#ASPGENHereditaryBreastOvarianCancerForm [type="radio"].with-gap:checked+label:after,
div#ASPGENHereditaryBreastOvarianCancerForm2 [type="radio"]:checked+label:after, 
div#ASPGENHereditaryBreastOvarianCancerForm2 [type="radio"].with-gap:checked+label:after {
    background-color: #006b2d;
    z-index: 0;
}

div#HBOCClicnicalInfoForm [type="radio"]:checked+label:after, 
div#HBOCClicnicalInfoForm [type="radio"].with-gap:checked+label:before, 
div#HBOCClicnicalInfoForm [type="radio"].with-gap:checked+label:after,
div#KitShippingInfoHBOCForm2 [type="radio"]:checked+label:after, 
div#KitShippingInfoHBOCForm2 [type="radio"].with-gap:checked+label:before, 
div#KitShippingInfoHBOCForm2 [type="radio"].with-gap:checked+label:after,
div#HBOCClicnicalInfoForm2 [type="radio"]:checked+label:after, 
div#HBOCClicnicalInfoForm2 [type="radio"].with-gap:checked+label:before, 
div#HBOCClicnicalInfoForm2 [type="radio"].with-gap:checked+label:after,
div#KitShippingInfoHBOCForm [type="radio"]:checked+label:after, 
div#KitShippingInfoHBOCForm [type="radio"].with-gap:checked+label:before, 
div#KitShippingInfoHBOCForm [type="radio"].with-gap:checked+label:after,
div#HBOCFamilyHistoryInfoForm [type="radio"]:checked+label:after, 
div#HBOCFamilyHistoryInfoForm [type="radio"].with-gap:checked+label:before, 
div#HBOCFamilyHistoryInfoForm [type="radio"].with-gap:checked+label:after,
div#HBOCFamilyHistoryInfoForm2 [type="radio"]:checked+label:after, 
div#HBOCFamilyHistoryInfoForm2 [type="radio"].with-gap:checked+label:before, 
div#HBOCFamilyHistoryInfoForm2 [type="radio"].with-gap:checked+label:after,
div#DiagnosisCodes_ICD10CodesHBOCForm [type="radio"]:checked+label:after, 
div#DiagnosisCodes_ICD10CodesHBOCForm [type="radio"].with-gap:checked+label:before, 
div#DiagnosisCodes_ICD10CodesHBOCForm [type="radio"].with-gap:checked+label:after,
div#ASPGENHereditaryBreastOvarianCancerForm [type="radio"]:checked+label:after, 
div#ASPGENHereditaryBreastOvarianCancerForm [type="radio"].with-gap:checked+label:before, 
div#ASPGENHereditaryBreastOvarianCancerForm [type="radio"].with-gap:checked+label:after,
div#ASPGENHereditaryBreastOvarianCancerForm2 [type="radio"]:checked+label:after, 
div#ASPGENHereditaryBreastOvarianCancerForm2 [type="radio"].with-gap:checked+label:before, 
div#ASPGENHereditaryBreastOvarianCancerForm2 [type="radio"].with-gap:checked+label:after {
    border: 2px solid #006b2d;
}
div#KitShippingInfoHBOCForm2 p,
div#HBOCClicnicalInfoForm p,
div#HBOCClicnicalInfoForm2 p,
div#KitShippingInfoHBOCForm p,
div#DiagnosisCodes_ICD10CodesHBOCForm p,
div#HBOCFamilyHistoryInfoForm p,
div#HBOCFamilyHistoryInfoForm2 p,
div#ASPGENHereditaryBreastOvarianCancerForm p {color: #006b2d; } */

.remove_button.btn {
	position: relative;
	left:96%;
	top:-193px;
}
.modalcss {
	margin: 5px 0px 0px;
}
.bg-div {
	width: 100%;
	height: 40px;
	border: 20px solid #1B6380;
}
.panel-titlepdf1 {
	background-color: transparent /*#1B6380*/ !important;
	color: #fff;	
	display: block;
	padding: 10px 15px;
	font-weight: 600;
	font-size: 16px;
	margin-top: -40px;
}
.clinicalp {
	margin: 0px; padding: 0px;
}
.custom-margin-bottom-clinicalp {
	margin-bottom: 5px;
}
.provider_order.provider_order_pdf .checkbox label, .provider_order.provider_order_pdf .radio label, .provider_order.provider_order_pdf label {    
	font-weight: 600;
	margin-bottom: .1rem;
}
.provider_order.provider_order_pdf .form-group {
    width: 100%;
    margin-bottom: 1px;
}
.provider_order.provider_order_pdf .form-group {
    padding-bottom: 2px;
    margin: 1px 0 0 0;
}
.provider_order.provider_order_pdf .form-group .form-control {
	margin-bottom: 1px;
	height: 28px;
    padding: 2px 0;
}

.form-group select.form-control.show-tick {
	/* background: url("/assets/images/dropdown-select.svg") 96% no-repeat; */
  background: url("./assets/images/dropdown-select.svg") 96% no-repeat;
}
.headTitle {
	color: #F7901E;
	font-size: 20px;
	font-weight: 700;
	padding: 0px;
	margin: 0px;
	font-family: Roboto, 'Segoe UI', Tahoma, sans-serif;
}
.headName{
	color: #006471;
	font-size: 24px;
	font-weight: 500;
	padding: 0px;
	margin: 0px;
	font-family: Roboto, 'Segoe UI', Tahoma, sans-serif;
}
.baylor_bg {
	background: #E8E9EA;
	padding: 20px;
	text-align: left;
	margin: 10px 10px 0px 0px;
	min-height: 260px;
	height: auto;
}
.baylor_bg.baylor_bg_last {
	background: #E8E9EA;
	padding: 20px;
	text-align: left;
	margin: 10px 0px 0px 0px;
}
.infoTitle {
	color: #006471;
	font-size: 14px;
	font-weight: 700;
	padding: 0px;
	margin: 0px;
	font-family: 'Roboto', 'Segoe UI', Tahoma, sans-serif;
}
.infoValues {
	color: #222;
	font-size: 14px;
	font-weight: 400;
	padding: 0px;
	margin: 0px;
	font-family: 'Roboto', 'Segoe UI', Tahoma, sans-serif;
}
.reportValues {
	color: #009456;
	font-size: 16px;
	font-weight: 700;
	padding: 0px;
	margin: 0px;
	font-family: 'Roboto', 'Segoe UI', Tahoma, sans-serif;
}
.report_bg {
	background: #fff;
	padding: 20px;
	text-align: left;
	margin: 10px 0px 10px 0px;
	border: 10px solid #E8E9EA;
}
.reportHead {
	color: #222;
	font-size: 18px;
	font-weight: 700;
	padding: 0px;
	margin: 0px;
	font-family: 'Roboto', 'Segoe UI', Tahoma, sans-serif;	
}
.reportHead2 {
	color: #016679;
	font-size: 14px;
	font-weight: 700;
	padding: 0px 0px 0px;
	margin:  10px 0px 0px;
	font-family: 'Roboto', 'Segoe UI', Tahoma, sans-serif;
	
}
ul.reportText {	
	padding: 0px;
	margin: 0px;
}
ul.reportText li {
	padding: 0px;
	margin-left: 17px;
	font-size: 14px;
	font-weight: 500;	
	font-family: 'Roboto', 'Segoe UI', Tahoma, sans-serif;
	color: #222;
}
.genesText {
	color: #222;
	font-size: 16px;
	font-weight: 700;
	padding: 0px 0px 0px;
	margin:  0px 0px 0px;
	font-family: 'Roboto', 'Segoe UI', Tahoma, sans-serif;
}
.tableLayout {
	padding: 0px;
	margin: 0px;
	border: 1px solid #E8E9EA;
}
.border-r{
	border-right: 1px solid #E8E9EA;
}
.border-b {
	border-bottom: 1px solid #E8E9EA;
}
.tableTextHead {
	color: #222;
	font-size: 14px;
	font-weight: 700;	
	font-family: 'Roboto', 'Segoe UI', Tahoma, sans-serif;
	text-align: center;
}
.tableText{
	color: #222;
	font-size: 14px;
	font-weight: 500;	
	font-family: 'Roboto', 'Segoe UI', Tahoma, sans-serif;
}
.padd-t-20 {
	padding-top: 20px;
}
.reportTextHead {
	color: #fff;
	font-size: 16px;
	font-weight: 600;	
	font-family: 'Roboto', 'Segoe UI', Tahoma, sans-serif;
	background: #006272;
	padding: 4px;
	margin: 0px;
}
.reportText {
	color: #222;
	font-size: 14px;
	font-weight: 500;	
	font-family: 'Roboto', 'Segoe UI', Tahoma, sans-serif;	
	padding: 4px;
	margin: 0px;
}
.baylor_bg2 {
	background: #E8E9EA;
	padding: 20px;
	text-align: left;
	margin: 10px 0px 0px 0px;
	min-height: 20px;
	height: auto;
}
.reportText2 {
	color: #222;
	font-size: 14px;
	font-weight: 500;	
	font-family: 'Roboto', 'Segoe UI', Tahoma, sans-serif;	
	padding: 2px;
	margin: 0px;
}
@media only screen and (max-width: 992px) {
	.light.rtl section.content {
		margin: 55px 0px 15px 0px;
	}
}
@media only screen and (max-width: 500px) {
	.light section.content {
		margin-left: 0px !important;
	}
	.navbar-light .navbar-toggler {display: block;}	
	.navbar-nav.nav-big>li>a {
		padding: 7px 2px 5px 2px;
		margin-top: 15px;
		margin-left: 5px;
		font-size: 15px;
	}
	section.content {
		margin: 10px 15px 15px 5px;
	}
	.breadcrumb-area {		
		margin: 80px 0px 0px;		
	}
}

.lottie_area2_bg2 {
	width: 160px;
	height: 44px;
	margin: 0px auto;
	padding: 0px;
	background: #333333;
	border-radius: 6px;
	/* float: left; */
}
.lottie_area22 {
	width: 44px;
	height: 44px;
	margin: 0px auto;
	padding: 0px;
}

@media only screen and (max-width: 500px) { 
	div#QrCode {
		width: 100%;
		height: auto;
		float: left;
		text-align: center;
		padding: 0px;
		margin: 0px;
	}	
}