/* Pasha styles – Brand-overrides, can split these out */
:root {
  --accent-color: #ed5f74;
  --success-color: #5fed82;
  --headline-color: var(--accent-color);
  --logo-image: url("https://storage.googleapis.com/stripe-sample-images/logo-pasha.svg");
}

.pasha-image-stack {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: auto auto;
}

.pasha-image-stack img {
  border-radius: var(--radius);
  background-color: var(--gray-border);
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  transition: all 0.8s ease;
  opacity: 0;
}

.pasha-image-stack img:nth-child(1) {
  transform: translate(12px, -12px);
  opacity: 1;
}
.pasha-image-stack img:nth-child(2) {
  transform: translate(-24px, 16px);
  opacity: 1;
}
.pasha-image-stack img:nth-child(3) {
  transform: translate(68px, -100px);
  opacity: 1;
}

.sample-info {
  padding: 20px 30px;
  border: 1px solid var(--gray-border);
  border-radius: var(--radius);

  position: fixed;
  top: 10px;
  left: 50%;
  margin-left: -250px;
  max-width: 500px;
}
.showFormContent {display: block;}
.hideFormContent {display: none;}
.custom-min-height {min-height: 45px;}
.custom-min-height2 {min-height: 50px;}
@media (max-width: 720px) {
  .sample-info {
    top: 0;
  }
}

.input-container {
  width: 100%;
  margin-bottom: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 0px solid #e5e5e5;
  transition: .2s ease-in-out;
  transition-property: color,background-color,borde
}
.input.ph-no {
  display: flex;
  height: 40px;
  font-size: 16px;  
  padding: 0 3px;
  background: #fff;
  color: #666;
  outline: none;
  border: 0;
}
.input.area-code.ph-no {
    width: 36px;
}
.input.prefix.ph-no {
  width: 36px;
}
.input.suffix.ph-no {
  width: 48px;
} 
.dash,.parenthesis {
  display: flex;
}
.btn {
   cursor: pointer;
}


/* css for the addresses in the physician section */
.addressPhy {
  font-weight: bold;
  font-size: 20px;
  position: relative;
  top: 45px;
  left: 15px;
}
.addressPhySpan {
  font-style: italic;
  font-size: 12px;
}


/* submit button for the physician information section */
.subPat {
  margin-right: 5px;

}

.billInfoOva {
  font-size: .86rem;
  position: relative;
  top: -10px;
}

.btn-family-history-add-new {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

[type="radio"].filled-in:checked+label:after, [type="radio"].with-gap:checked+label:after {
  background-color: #26a69a;
  z-index: 0;
}
.checkbox-for-pdf {
  background-color: #555;
  z-index: 666;
  border-radius: 100%;
  position: relative;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  border: 2px solid #555;
  float: left;
}

.danger-info {
  color: red;
}

.sigPad {
  width: 400px;
  height: 300px;
}