/* FOR DELETE MODAL */
#overlayExtended {
    position: fixed; /* Sit on top of the page content */
    /* display: none; Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    /* z-index: 2;  */
    /* cursor: pointer;  */
  }
.modalExtended {
    z-index: 3;
    width: 500px;
    background: white;
    border: 1px solid #ccc;
    transition: 1.1s ease-out;
    box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
    
    position: fixed;
     top: 20%;
    left: 30%;

    /*
    margin-top: -50px;
    margin-left: -50px; */

    margin: 0 auto;
    
  }
  .modalExtended.off {
    opacity: 0;
    visibility: hidden;
    filter: blur(8px);
    transform: scale(0.33);
    box-shadow: 1rem 0 0 rgba(0, 0, 0, 0.2);
  }
  @supports (offset-rotation: 0deg) {
    offset-rotation: 0deg;
    offset-path: path("M 250,100 S -300,500 -700,-200");
    .modalExtended.off {
      offset-distance: 100%;
    }
  }
  @media (prefers-reduced-motion) {
    .modalExtended {
      offset-path: none;
    }
  }
  .modalExtended h2 {
    border-bottom: 1px solid #ccc;
    padding: 1rem;
    margin: 0;
  }
  .modalExtended .content {
    padding: 1rem;
  }
  .modalExtended .actions {
    border-top: 1px solid #ccc;
    background: #eee;
    padding: 0.5rem 1rem;
  }
  .modalExtended .actions button {
    border: 0;
    background: #78f89f;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    line-height: 1;
  }
  #centered-toggle-button {
    position: absolute;
  }
  /* FOR DELETE MODAL */


  .actualHome .modalExtended {
    top: 10%;
  }

  .centerDiv {
    width: 60%;
      height:200px;
      margin: 0 auto;
      background-color:#FFA500 ;
  }